import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@core/uuid/uuid';
import { RegulationCollectionItemOperationsResponse } from '@api/regulation/responses/regulation-collection-item-operations.response';

@jsonObject
export class RegulationCollectionItemResponse {
  @jsonMember readonly id!: Uuid;
  @jsonMember readonly name!: string;
  @jsonMember readonly createdAt!: Date;
  @jsonMember({
    name: 'contextOperations',
  })
  readonly operations!: RegulationCollectionItemOperationsResponse;
}
