export class UserPermissions {
  constructor(
    readonly canCreateCustomer: boolean,
    readonly canCreateAdmin: boolean,
    readonly canCreateOffer: boolean,
    readonly canCreateRegulation: boolean,
    readonly hasAccessCustomers: boolean,
    readonly hasAccessOffers: boolean,
    readonly hasAccessAdmins: boolean,
    readonly hasAccessEmployees: boolean,
    readonly hasAccessStatistics: boolean,
    readonly hasAccessRegulations: boolean,
  ) {}
}
