import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ActivationRequest {
  @jsonMember readonly password!: string;

  constructor(password: string) {
    this.password = password;
  }
}
