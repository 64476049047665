import { Injectable } from '@angular/core';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { ApiParams } from '@environments/enums/api-params';
import { isNullable } from '@core/utils/types/nullable/is-nullable';
import { RequiredMethodCallException } from '@core/exceptions/required-method-call.exception';
import { environment } from '@environments/environment';
import { QueryParamsTransformerService } from '@core/json/services/query-params-transformer.service';
import { hasKeys } from '@core/utils/types/object/has-keys';

@Injectable({
  providedIn: 'root',
})
export class ApiUrlBuilder {
  private _host: Nullable<string>;
  private _path: Nullable<string>;
  private _params: Nullable<{ [key in ApiParams]?: string }>;
  private _queryParams: Nullable<Object>;

  constructor(
    private readonly queryParamsTransformer: QueryParamsTransformerService,
  ) {
    this.reset();
  }

  reset(): this {
    this._path = null;
    this._params = null;
    this._queryParams = null;

    return this;
  }

  host(host: string): this {
    this._host = host;

    return this;
  }

  path(path: string): this {
    this._path = path;

    return this;
  }

  params(params: { [key in ApiParams]?: string }): this {
    this._params = params;

    return this;
  }

  queryParams(queryParams: Object): this {
    this._queryParams = queryParams;

    return this;
  }

  build(): string {
    this.requireHost();
    this.requirePath();

    let url = this.url();

    if (this.hasValidParams(this._params)) {
      url = this.appendParams(url, this._params);
    }

    if (this.hasValidQueryParams(this._queryParams)) {
      url = this.appendQueryParams(url, this._queryParams);
    }

    this.reset();

    return url;
  }

  private requireHost(): void {
    if (!isNullable(this._host)) {
      return;
    }

    throw new RequiredMethodCallException(this.host.name);
  }

  private requirePath(): void {
    if (!isNullable(this._path)) {
      return;
    }

    throw new RequiredMethodCallException('path');
  }

  private url(): string {
    return `${this._host}/${this._path}`;
  }

  private appendParams(
    path: string,
    params: {
      [key in ApiParams]?: string;
    },
  ): string {
    return Object.entries(params).reduce(
      (url, [key, value]) => url.replace(key, value),
      `${path}`,
    );
  }

  private appendQueryParams(path: string, queryParams: Object): string {
    return `${path}?${this.queryParamsTransformer.stringify(queryParams)}`;
  }

  private hasValidParams(
    params: Nullable<{ [key in ApiParams]?: string }>,
  ): params is { [key in ApiParams]?: string } {
    return !isNullable(params) && hasKeys(params);
  }

  private hasValidQueryParams(
    queryParams: Nullable<Object>,
  ): queryParams is Object {
    return !isNullable(queryParams) && hasKeys(queryParams);
  }
}
