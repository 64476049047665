import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { HttpHeader } from '@core/http/enums/http-header';
import { map, Observable } from 'rxjs';
import { AuthService } from '@core/auth/services/auth.service';

@Injectable()
export class HttpRequestHeaderService {
  constructor(private readonly auth: AuthService) {}

  appendAuthorizationHeader<Body>(
    request: HttpRequest<Body>,
  ): Observable<HttpRequest<Body>> {
    return this.auth.fetchAuthContext().pipe(
      map(({ token }) => {
        return request.clone({
          setHeaders: {
            [HttpHeader.AUTHORIZATION]: `Bearer ${token}`,
          },
        });
      }),
    );
  }
}
