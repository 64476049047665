import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { RouterLinkNavigatorService } from '@core/routing/services/router-link-navigator.service';
import { ActivatedRouteUtils } from '@core/routing/utils/activated-route.utils';
import { catchError, EMPTY } from 'rxjs';
import { RouterPathParam } from '@core/routing/enums/router-path-param';
import { RouterPath } from '@core/routing/enums/router-path';
import { FetchPreviewRegulationContentService } from '@features/regulations/services/fetch-preview-regulation-content.service';

export const PreviewRegulationContentResolver: ResolveFn<string> = (
  route,
  state,
  fetch = inject(FetchPreviewRegulationContentService),
  navigator = inject(RouterLinkNavigatorService),
  utils = inject(ActivatedRouteUtils),
) => {
  const id = utils.uuidParamFromSnapshot(RouterPathParam.REGULATION_ID, route);
  const navigateToNotFoundPage = () => navigator.navigate(RouterPath.NOT_FOUND);

  return fetch.content(id).pipe(
    catchError(() => {
      navigateToNotFoundPage();

      return EMPTY;
    }),
  );
};
