import { Injectable } from '@angular/core';
import { GlobalPermitsResponse } from '@api/auth/responses/global-permits.response';
import { UserPermissions } from '@core/auth/models/user-permissions';

@Injectable({
  providedIn: 'root',
})
export class PermitsConverter {
  convert(response: GlobalPermitsResponse): UserPermissions {
    return new UserPermissions(
      response.canCreateCustomer,
      response.canCreateAdmin,
      response.canCreateOffer,
      response.canCreateRegulations,
      response.hasAccessCustomers,
      response.hasAccessOffers,
      response.hasAccessAdmins,
      response.hasAccessEmployees,
      response.hasAccessStatistics,
      response.hasAccessRegulations,
    );
  }
}
