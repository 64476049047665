import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonErrorResponse } from '@api/core/responses/common-error.response';
import { JsonTransformerService } from '@core/json/services/json-transformer.service';
import { ValidationErrorResponse } from '@api/core/responses/validation-error.response';
import { AuthErrorResponse } from '@api/core/responses/auth-error.response';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorResponseHandler {
  constructor(private readonly jsonTransformer: JsonTransformerService) {}

  handleAuthError(): (response: HttpErrorResponse) => Observable<never> {
    return (response: HttpErrorResponse) => {
      const { error } = response;

      return throwError(() =>
        this.jsonTransformer.toObject(error, AuthErrorResponse),
      );
    };
  }

  handleCommonError(): (response: HttpErrorResponse) => Observable<never> {
    return (response: HttpErrorResponse) => {
      const { error } = response;

      return throwError(() =>
        this.jsonTransformer.toObject(error, CommonErrorResponse),
      );
    };
  }

  handleValidationError(): (response: HttpErrorResponse) => Observable<never> {
    return (response: HttpErrorResponse) => {
      const { error } = response;

      return throwError(() =>
        this.jsonTransformer.toObject(error, ValidationErrorResponse),
      );
    };
  }
}
