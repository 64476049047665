import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '@shared/spinner/spinner.component';
import { Observable } from 'rxjs';
import { HttpLoadingService } from '@core/http/services/http-loading.service';

@Component({
  selector: 'app-loading-indicator',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
  readonly loading$: Observable<boolean>;

  constructor(private readonly loading: HttpLoadingService) {
    this.loading$ = this.loading.loading$;
  }
}
