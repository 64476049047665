import { ConfirmationResultType } from '@shared/confirmation/enums/confirmation-result-type';

export class ConfirmationResult {
  constructor(readonly type: ConfirmationResultType) {}

  isReject(): boolean {
    return this.type === ConfirmationResultType.REJECT;
  }

  isAccept(): boolean {
    return this.type === ConfirmationResultType.ACCEPT;
  }
}
