import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@core/uuid/uuid';

@jsonObject
export class FilePreviewResponse {
  @jsonMember readonly id!: Uuid;
  @jsonMember readonly previewUrl!: string;
  @jsonMember readonly mimeType!: string;
  @jsonMember readonly size!: number;
}
