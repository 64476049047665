export enum ApiParams {
  CUSTOMER_ID = '{a2edadbf-c5bc-4ccd-a5c3-9152841a22a3}',
  OFFER_ID = '{209eb641-7a93-47e4-9027-98a7de2c026c}',
  ADMIN_ID = '{0062e55e-659b-11ed-9022-0242ac120002}',
  FILE_ID = '{e6c54239-a4ca-412e-bfac-1e1c61961c54}',
  ACTIVATION_TOKEN = '{96976f67-4470-45bc-afcc-86c2f22b9aaa}',
  REGULATION_ID = '{1eb54a55-a85e-41e1-a4ba-c7033a4222b7}',
  NIP = '{f7d0c402-b878-4f54-8199-e699194eaf10}',
  APPLICATION_ID = '{c3617600-606d-401f-8c3a-d47bf1252259}',
}
