import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from '@api/core/services/api-helper.service';
import { environment } from '@environments/environment';
import { CollectionRequest } from '@api/core/requests/collection.request';
import { map, Observable } from 'rxjs';
import { CollectionResponse } from '@api/core/responses/collection.response';
import { OfferCollectionFilterRequest } from '@api/offers/filters/offer-collection-filter.request';
import { OfferCollectionItemResponse } from '@api/offers/responses/offer-collection-item.response';
import { Uuid } from '@core/uuid/uuid';
import { ApiParams } from '@environments/enums/api-params';
import { CreateOfferRequest } from '@api/offers/requests/create-offer.request';
import { CreateResourceResponse } from '@api/core/responses/create-resource.response';
import { OfferDetailsResponse } from '@api/offers/responses/offer-details.response';
import { UpdateOfferRequest } from '@api/offers/requests/update-offer.request';
import { OfferSharePreviewResponse } from '@api/offers/responses/offer-share-preview.response';
import { ApiUrlFactory } from '@api/factories/api-url.factory';

const { OFFERS } = environment.api;

@Injectable({
  providedIn: 'root',
})
export class OffersApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly helper: ApiHelperService,
    private readonly apiUrlFactory: ApiUrlFactory,
  ) {}

  collection(
    request: CollectionRequest<OfferCollectionFilterRequest>,
  ): Observable<CollectionResponse<OfferCollectionItemResponse>> {
    const { LIST, HOST_URL } = OFFERS;
    const queryParams = this.helper.collectionRequestToQueryParams(
      request,
      OfferCollectionFilterRequest,
    );
    const url = this.apiUrlFactory.complex((builder) =>
      builder.host(HOST_URL).path(LIST).queryParams(queryParams).build(),
    );

    return this.http
      .get(url)
      .pipe(
        map(this.helper.bodyToCollectionResponse(OfferCollectionItemResponse)),
      );
  }

  remove(id: Uuid): Observable<void> {
    const { REMOVE, HOST_URL } = OFFERS;
    const url = this.apiUrlFactory.withParams(HOST_URL, REMOVE, {
      [ApiParams.OFFER_ID]: id,
    });
    const body = this.helper.emptyBody();

    return this.http.delete<void>(url, body);
  }

  details(id: Uuid): Observable<OfferDetailsResponse> {
    const { DETAILS, HOST_URL } = OFFERS;
    const url = this.apiUrlFactory.withParams(HOST_URL, DETAILS, {
      [ApiParams.OFFER_ID]: id,
    });

    return this.http
      .get(url)
      .pipe(map(this.helper.bodyToResponse(OfferDetailsResponse)));
  }

  create(request: CreateOfferRequest): Observable<CreateResourceResponse> {
    const { CREATE, HOST_URL } = OFFERS;
    const url = this.apiUrlFactory.simple(HOST_URL, CREATE);
    const body = this.helper.requestToBody(request, CreateOfferRequest);

    return this.http
      .post(url, body)
      .pipe(map(this.helper.bodyToResponse(CreateResourceResponse)));
  }

  update(id: Uuid, request: UpdateOfferRequest): Observable<void> {
    const { UPDATE, HOST_URL } = OFFERS;
    const url = this.apiUrlFactory.withParams(HOST_URL, UPDATE, {
      [ApiParams.OFFER_ID]: id,
    });
    const body = this.helper.requestToBody(request, UpdateOfferRequest);

    return this.http.put<void>(url, body);
  }

  sharePreview(id: Uuid): Observable<OfferSharePreviewResponse> {
    const { SHARE_PREVIEW, HOST_URL } = OFFERS;
    const url = this.apiUrlFactory.withParams(HOST_URL, SHARE_PREVIEW, {
      [ApiParams.OFFER_ID]: id,
    });

    return this.http
      .get(url)
      .pipe(map(this.helper.bodyToResponse(OfferSharePreviewResponse)));
  }
}
