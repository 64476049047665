import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { HttpLoadingService } from '@core/http/services/http-loading.service';
import { SkipByLoadingInterceptor } from '@core/http/context/tokens/skip-by-loading-interceptor';

@Injectable()
export class HttpLoadingInterceptor<Body> implements HttpInterceptor {
  constructor(private readonly loading: HttpLoadingService) {}

  intercept(
    request: HttpRequest<Body>,
    next: HttpHandler,
  ): Observable<HttpEvent<Body>> {
    if (this.isSkippedRequest(request)) {
      return next.handle(request);
    }

    const { url } = request;

    this.loading.loadingFor(url);

    return next.handle(request).pipe(
      finalize(() => this.loading.unloadingFor(url)),
      catchError((error) => {
        this.loading.unloadingFor(url);

        return throwError(() => error);
      }),
    );
  }

  private isSkippedRequest(request: HttpRequest<Body>): boolean {
    return request.context.has(SkipByLoadingInterceptor);
  }
}
