import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Toast } from '@shared/toast/models/toast';
import { ToastSeverity } from '@shared/toast/enums/toast-severity';

@Injectable({
  providedIn: 'root',
})
export class ToastToPrimengMessageConverter {
  convert(toast: Toast): Message {
    const { content, title, severity, life } = toast;

    return {
      detail: content,
      summary: title,
      life: life,
      severity: this.convertToPrimengSeverity(severity),
    };
  }

  private convertToPrimengSeverity(severity: ToastSeverity): string {
    switch (severity) {
      case ToastSeverity.FAILURE:
        return 'error';

      case ToastSeverity.SUCCESS:
        return 'success';

      case ToastSeverity.WARNING:
        return 'warn';

      case ToastSeverity.INFO:
        return 'info';
    }
  }
}
