import { Injectable } from '@angular/core';
import { Uuid } from '@core/uuid/uuid';
import { map, Observable } from 'rxjs';
import { Offer } from '@features/offers/models/offer';
import { OffersApiService } from '@api/offers/offers-api.service';
import { OfferFactory } from '@features/offers/factories/offer.factory';

@Injectable({
  providedIn: 'root',
})
export class FetchOfferService {
  constructor(
    private readonly api: OffersApiService,
    private readonly offerFactory: OfferFactory,
  ) {}

  byId(id: Uuid): Observable<Offer> {
    return this.api
      .details(id)
      .pipe(map((response) => this.offerFactory.fromResponse(response)));
  }
}
