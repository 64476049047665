import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { environment } from '@environments/environment';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { routes } from '@core/routing/routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { HttpRequestHeaderService } from '@core/http/services/http-request-header.service';
import { AuthInitializer } from '@core/auth/initializers/auth-initializer';
import { Observable } from 'rxjs';
import { HttpAuthTokenInterceptor } from '@core/http/interceptors/http-auth-token.interceptor';
import { HttpRefreshTokenInterceptor } from '@core/http/interceptors/http-refresh-token.interceptor';
import { HttpLoadingInterceptor } from '@core/http/interceptors/http-loading.interceptor';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      RouterModule.forRoot(routes),
    ),
    MessageService,
    HttpRequestHeaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: (initializer: AuthInitializer) => (): Observable<void> =>
        initializer.initialize(),
      deps: [AuthInitializer],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
  ],
}).catch((error) => console.error(error));
