import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { map } from 'rxjs';
import { UrlTreeProvider } from '@core/routing/providers/url-tree.provider';
import { RouterPath } from '@core/routing/enums/router-path';
import { AuthService } from '@core/auth/services/auth.service';

export const OnlyLoggedGuard: CanActivateFn = (
  route,
  state,
  auth = inject(AuthService),
  urlTreeProvider = inject(UrlTreeProvider),
) => {
  const signOutPageUrlTree = (): UrlTree =>
    urlTreeProvider.urlTree(RouterPath.SIGN_OUT);

  return auth
    .isLoggedIn()
    .pipe(map((isLogged) => isLogged || signOutPageUrlTree()));
};
