import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError, EMPTY } from 'rxjs';
import { RouterLinkNavigatorService } from '@core/routing/services/router-link-navigator.service';
import { ActivatedRouteUtils } from '@core/routing/utils/activated-route.utils';
import { FetchSharePreviewOfferContentService } from '@features/offers/services/fetch-share-preview-offer-content.service';
import { RouterPathParam } from '@core/routing/enums/router-path-param';
import { RouterPath } from '@core/routing/enums/router-path';

export const SharePreviewOfferContentResolver: ResolveFn<string> = (
  route,
  state,
  fetch = inject(FetchSharePreviewOfferContentService),
  navigator = inject(RouterLinkNavigatorService),
  utils = inject(ActivatedRouteUtils),
) => {
  const id = utils.uuidParamFromSnapshot(RouterPathParam.OFFER_ID, route);
  const navigateToNotFoundPage = (): void =>
    navigator.navigate(RouterPath.NOT_FOUND);

  return fetch.content(id).pipe(
    catchError(() => {
      navigateToNotFoundPage();

      return EMPTY;
    }),
  );
};
