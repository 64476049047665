import { Injectable } from '@angular/core';
import { LocalRepositoryService } from '@core/storage/local-repository.service';
import { AuthContext } from '@core/auth/models/auth-context';
import { AuthContextNotFoundException } from '@core/auth/exceptions/auth-context-not-found.exception';
import { isNullable } from '@core/utils/types/nullable/is-nullable';
import { TokenNotFoundException } from '@core/auth/exceptions/token-not-found.exception';
import { RefreshTokenNotFoundException } from '@core/auth/exceptions/refresh-token-not-found.exception';

@Injectable({
  providedIn: 'root',
})
export class AuthContextStorage {
  private readonly TOKEN_STORAGE_KEY = 'e43c9767-7c5a-4ad3-8add-e3507f26c987';
  private readonly REFRESH_TOKEN_STORAGE_KEY =
    '748b0005-b103-4c35-880f-26311c70f561';

  constructor(private readonly localStorage: LocalRepositoryService) {}

  store(context: AuthContext): void {
    const { token, refreshToken } = context;

    this.storeToken(token);
    this.storeRefreshToken(refreshToken);
  }

  fetch(): AuthContext {
    try {
      const token = this.fetchToken();
      const refreshToken = this.fetchRefreshToken();

      return new AuthContext(token, refreshToken);
    } catch (error) {
      throw new AuthContextNotFoundException();
    }
  }

  fetchToken(): string {
    const token = this.localStorage.getItem(this.TOKEN_STORAGE_KEY);

    if (isNullable(token)) {
      throw new TokenNotFoundException();
    }

    return token;
  }

  fetchRefreshToken(): string {
    const refreshToken = this.localStorage.getItem(
      this.REFRESH_TOKEN_STORAGE_KEY,
    );

    if (isNullable(refreshToken)) {
      throw new RefreshTokenNotFoundException();
    }

    return refreshToken;
  }

  clear(): void {
    this.clearToken();
    this.clearRefreshToken();
  }

  private clearToken(): void {
    return this.localStorage.removeItem(this.TOKEN_STORAGE_KEY);
  }

  private clearRefreshToken(): void {
    return this.localStorage.removeItem(this.REFRESH_TOKEN_STORAGE_KEY);
  }

  private storeToken(token: string): void {
    this.localStorage.setItem(this.TOKEN_STORAGE_KEY, token);
  }

  private storeRefreshToken(token: string): void {
    this.localStorage.setItem(this.REFRESH_TOKEN_STORAGE_KEY, token);
  }
}
