import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpLoadingService {
  private readonly loadingSource$ = new BehaviorSubject(false);
  private readonly requests = new Map<string, boolean>();

  get loading$(): Observable<boolean> {
    return this.loadingSource$.asObservable();
  }

  loadingFor(url: string): void {
    this.requests.set(url, true);

    this.loadingSource$.next(true);
  }

  unloadingFor(url: string): void {
    if (this.requests.has(url)) {
      this.requests.delete(url);
    }

    if (this.requests.size === 0) {
      this.loadingSource$.next(false);
    }
  }
}
