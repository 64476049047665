import { ConfirmationMessageType } from '@shared/confirmation/enums/confirmation-message-type';

export class ConfirmationMessage {
  constructor(
    readonly title: string,
    readonly content: string,
    readonly type: ConfirmationMessageType,
    readonly acceptLabel: string,
    readonly rejectLabel: string,
  ) {}
}
