import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class SignInRequest {
  @jsonMember readonly username!: string;
  @jsonMember readonly password!: string;

  constructor(username: string, password: string) {
    this.username = username;
    this.password = password;
  }
}
