import { Injectable } from '@angular/core';
import { Nullable } from '@core/utils/types/nullable/nullable';

@Injectable({
  providedIn: 'root',
})
export class LocalRepositoryService {
  private readonly _localStorage = localStorage;

  setItem<Object>(key: string, value: string): void {
    this._localStorage.setItem(key, value);
  }

  getItem<Object>(key: string): Nullable<string> {
    return this._localStorage.getItem(key);
  }

  removeItem(key: string): void {
    this._localStorage.removeItem(key);
  }

  isExists(key: string): boolean {
    try {
      return !!this.getItem(key);
    } catch (error) {
      return false;
    }
  }
}
