import { Injectable } from '@angular/core';
import { OfferContextOperationsFactory } from '@features/offers/factories/offer-context-operations.factory';
import { Offer } from '@features/offers/models/offer';
import { OfferDetailsResponse } from '@api/offers/responses/offer-details.response';
import { ImageFileFactory } from '@features/files/factories/image-file.factory';

@Injectable({
  providedIn: 'root',
})
export class OfferFactory {
  constructor(
    private readonly operationsFactory: OfferContextOperationsFactory,
    private readonly imageFileFactory: ImageFileFactory,
  ) {}

  fromResponse(response: OfferDetailsResponse): Offer {
    return new Offer(
      response.id,
      response.name,
      response.lead,
      response.description,
      response.identifier,
      response.status,
      response.isDisposable,
      response.visibilityFor,
      response.beginsAt,
      response.expiresAt,
      response.createdAt,
      response.file && this.imageFileFactory.fromPreviewResponse(response.file),
      this.operationsFactory.fromResponse(response.operations),
      response.regulationId,
    );
  }
}
