import { Injectable } from '@angular/core';
import * as QueryString from 'qs';
import { JsonTypes } from 'typedjson';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsTransformerService {
  stringify(object: JsonTypes): string {
    return QueryString.stringify(object, {
      skipNulls: true,
      arrayFormat: 'repeat',
    });
  }
}
