import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { CollectionMetadataResponse } from './collection-metadata.response';

@jsonObject
export class CollectionResponse<Item> {
  @jsonMember readonly metadata!: CollectionMetadataResponse;
  @jsonArrayMember(AnyT) readonly collection!: Item[];

  constructor(metadata: CollectionMetadataResponse, collection: Item[]) {
    this.metadata = metadata;
    this.collection = collection;
  }
}
