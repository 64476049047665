import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { once } from '@core/utils/rx/operators/once';
import { isNullable } from '@core/utils/types/nullable/is-nullable';
import { SkipByRefreshInterceptor } from '@core/http/context/tokens/skip-by-refresh-interceptor';
import { HttpRequestHeaderService } from '@core/http/services/http-request-header.service';
import { AuthService } from '@core/auth/services/auth.service';

@Injectable()
export class HttpRefreshTokenInterceptor<Body> implements HttpInterceptor {
  private readonly refreshTokenSubject$ = new BehaviorSubject<Nullable<void>>(
    null,
  );

  private refreshTokenInProgress = false;

  constructor(
    private readonly auth: AuthService,
    private readonly requestHeader: HttpRequestHeaderService,
  ) {}

  intercept(
    request: HttpRequest<Body>,
    next: HttpHandler,
  ): Observable<HttpEvent<Body>> {
    if (this.isSkippedRequest(request)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (!this.isUnauthorized(error)) {
          return throwError(() => error);
        }

        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject$.pipe(
            filter((result) => !isNullable(result)),
            once(),
            switchMap(() =>
              this.requestHeader
                .appendAuthorizationHeader(request)
                .pipe(switchMap((request) => next.handle(request))),
            ),
          );
        }

        this.refreshTokenInProgress = true;
        this.refreshTokenSubject$.next(null);

        return this.auth.refreshToken().pipe(
          switchMap(() => {
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject$.next();

            return this.requestHeader
              .appendAuthorizationHeader(request)
              .pipe(switchMap((request) => next.handle(request)));
          }),
          catchError((error) => {
            this.refreshTokenInProgress = false;

            return throwError(() => error);
          }),
        );
      }),
    );
  }

  private isSkippedRequest(request: HttpRequest<Body>): boolean {
    return request.context.has(SkipByRefreshInterceptor);
  }

  private isUnauthorized(error: HttpErrorResponse): boolean {
    return error.status === HttpStatusCode.Unauthorized;
  }
}
