import { Nullable } from '@core/utils/types/nullable/nullable';
import { isNullable } from '@core/utils/types/nullable/is-nullable';

export const hasKeys = (value: Nullable<Object>): boolean => {
  if (isNullable(value)) {
    return false;
  }

  return !!Object.keys(value).length;
};
