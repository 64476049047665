import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@core/uuid/uuid';
import { Role } from '@core/auth/enums/role';
import { GlobalPermitsResponse } from '@api/auth/responses/global-permits.response';

@jsonObject
export class MeResponse {
  @jsonMember readonly id!: Uuid;
  @jsonMember readonly email!: string;
  @jsonArrayMember(String) readonly roles!: Role[];
  @jsonMember globalPermits!: GlobalPermitsResponse;
}
