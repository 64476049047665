import { Injectable } from '@angular/core';
import { CollectionRequest } from '@api/core/requests/collection.request';
import { map, Observable } from 'rxjs';
import { CollectionResponse } from '@api/core/responses/collection.response';
import { RegulationCollectionItemResponse } from '@api/regulation/responses/regulation-collection-item.response';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from '@api/core/services/api-helper.service';
import { ApiUrlFactory } from '@api/factories/api-url.factory';
import { RegulationCollectionFilterRequest } from '@api/regulation/filters/regulation-collection-filter.request';
import { environment } from '@environments/environment';
import { Uuid } from '@core/uuid/uuid';
import { ApiParams } from '@environments/enums/api-params';
import { CreateResourceResponse } from '@api/core/responses/create-resource.response';
import { CreateRegulationRequest } from '@api/regulation/requests/create-regulation.request';
import { UpdateRegulationRequest } from '@api/regulation/requests/update-regulation.request';
import { RegulationDetailsResponse } from '@api/regulation/responses/regulation-details.response';
import { RegulationPreviewResponse } from '@api/regulation/responses/regulation-preview.response';

const { REGULATIONS } = environment.api;

@Injectable({
  providedIn: 'root',
})
export class RegulationsApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly helper: ApiHelperService,
    private readonly apiUrlFactory: ApiUrlFactory,
  ) {}

  collection(
    request: CollectionRequest<RegulationCollectionFilterRequest>,
  ): Observable<CollectionResponse<RegulationCollectionItemResponse>> {
    const { LIST, HOST_URL } = REGULATIONS;
    const queryParams = this.helper.collectionRequestToQueryParams(
      request,
      RegulationCollectionFilterRequest,
    );
    const url = this.apiUrlFactory.complex((builder) =>
      builder.host(HOST_URL).path(LIST).queryParams(queryParams).build(),
    );

    return this.http
      .get(url)
      .pipe(
        map(
          this.helper.bodyToCollectionResponse(
            RegulationCollectionItemResponse,
          ),
        ),
      );
  }

  remove(id: Uuid): Observable<void> {
    const { REMOVE, HOST_URL } = REGULATIONS;
    const url = this.apiUrlFactory.withParams(HOST_URL, REMOVE, {
      [ApiParams.REGULATION_ID]: id,
    });
    const body = this.helper.emptyBody();

    return this.http.delete<void>(url, body);
  }

  details(id: Uuid): Observable<RegulationDetailsResponse> {
    const { DETAILS, HOST_URL } = REGULATIONS;
    const url = this.apiUrlFactory.withParams(HOST_URL, DETAILS, {
      [ApiParams.REGULATION_ID]: id,
    });

    return this.http
      .get(url)
      .pipe(map(this.helper.bodyToResponse(RegulationDetailsResponse)));
  }

  create(request: CreateRegulationRequest): Observable<CreateResourceResponse> {
    const { CREATE, HOST_URL } = REGULATIONS;
    const url = this.apiUrlFactory.simple(HOST_URL, CREATE);
    const body = this.helper.requestToBody(request, CreateRegulationRequest);

    return this.http
      .post(url, body)
      .pipe(map(this.helper.bodyToResponse(CreateResourceResponse)));
  }

  update(id: Uuid, request: UpdateRegulationRequest): Observable<void> {
    const { UPDATE, HOST_URL } = REGULATIONS;
    const url = this.apiUrlFactory.withParams(HOST_URL, UPDATE, {
      [ApiParams.REGULATION_ID]: id,
    });
    const body = this.helper.requestToBody(request, UpdateRegulationRequest);

    return this.http.put<void>(url, body);
  }

  preview(id: Uuid): Observable<RegulationPreviewResponse> {
    const { PREVIEW, HOST_URL } = REGULATIONS;
    const url = this.apiUrlFactory.withParams(HOST_URL, PREVIEW, {
      [ApiParams.REGULATION_ID]: id,
    });

    return this.http
      .get(url)
      .pipe(map(this.helper.bodyToResponse(RegulationPreviewResponse)));
  }
}
