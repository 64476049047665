import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { dateSerializer } from '@core/json/serializers/date-serializer';
import { OfferType } from '@features/offers/components/offer-type/enums/offer-type';

@jsonObject
export class OfferCollectionFilterRequest {
  @jsonMember name?: string;
  @jsonMember lead?: string;
  @jsonMember identifier?: string;
  @jsonMember status?: string;
  @jsonMember type?: OfferType;
  @jsonMember isDisposable?: boolean;
  @jsonArrayMember(String, { name: 'visibility' }) visibilityFor?: string[];
  @jsonMember({ serializer: dateSerializer() }) expiresAtFrom?: Date;
  @jsonMember({ serializer: dateSerializer() }) expiresAtTo?: Date;
  @jsonMember({ serializer: dateSerializer() }) createdAtFrom?: Date;
  @jsonMember({ serializer: dateSerializer() }) createdAtTo?: Date;
}
