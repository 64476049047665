import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import { FetchOfferService } from '@features/offers/services/fetch-offer.service';
import { Offer } from '@features/offers/models/offer';
import { ActivatedRouteUtils } from '@core/routing/utils/activated-route.utils';
import { RouterPathParam } from '@core/routing/enums/router-path-param';
import { RouterLinkNavigatorService } from '@core/routing/services/router-link-navigator.service';
import { RouterPath } from '@core/routing/enums/router-path';

export const OfferResolver: ResolveFn<Offer> = (
  route,
  state,
  fetch = inject(FetchOfferService),
  navigator = inject(RouterLinkNavigatorService),
  utils = inject(ActivatedRouteUtils),
): Observable<Offer> => {
  const id = utils.uuidParamFromSnapshot(RouterPathParam.OFFER_ID, route);

  const navigateToNotFoundPage = (): void =>
    navigator.navigate(RouterPath.NOT_FOUND);

  return fetch.byId(id).pipe(
    catchError(() => {
      navigateToNotFoundPage();

      return EMPTY;
    }),
  );
};
