import {
  ADMINS,
  AUTH,
  COMPANIES_PUBLIC,
  CUSTOMERS,
  EMPLOYEES,
  FILES,
  NOTIFICATIONS,
  OFFERS,
  PAINTERS,
  PAINTERS_PUBLIC,
  PROMOTION_PUBLIC,
  REGULATIONS,
  STATISTICS,
} from '@environments/consts/endpoints';

export const environment = {
  production: false,
  api: {
    AUTH: { ...AUTH, HOST_URL: 'https://api-test.ppg-qr.pl/admin' },
    NOTIFICATIONS: {
      ...NOTIFICATIONS,
      HOST_URL: 'https://api-test.ppg-qr.pl/admin',
    },
    CUSTOMERS: { ...CUSTOMERS, HOST_URL: 'https://api-test.ppg-qr.pl/admin' },
    ADMINS: { ...ADMINS, HOST_URL: 'https://api-test.ppg-qr.pl/admin' },
    OFFERS: { ...OFFERS, HOST_URL: 'https://api-test.ppg-qr.pl/admin' },
    FILES: { ...FILES, HOST_URL: 'https://api-test.ppg-qr.pl/admin' },
    EMPLOYEES: { ...EMPLOYEES, HOST_URL: 'https://api-test.ppg-qr.pl/admin' },
    STATISTICS: { ...STATISTICS, HOST_URL: 'https://api-test.ppg-qr.pl/admin' },
    REGULATIONS: {
      ...REGULATIONS,
      HOST_URL: 'https://api-test.ppg-qr.pl/admin',
    },
    PAINTERS: {
      ...PAINTERS,
      HOST_URL: 'https://api-test.ppg-qr.pl/admin',
    },
    PROMOTION_PUBLIC: {
      ...PROMOTION_PUBLIC,
      HOST_URL: 'https://api-test.ppg-qr.pl/influencer',
    },
    PAINTERS_PUBLIC: {
      ...PAINTERS_PUBLIC,
      HOST_URL: 'https://api-test.ppg-qr.pl/influencer',
    },
    COMPANIES_PUBLIC: {
      ...COMPANIES_PUBLIC,
      HOST_URL: 'https://api-test.ppg-qr.pl/influencer',
    },
  },
};
