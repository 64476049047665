import { Translation } from 'primeng/api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrimengTranslationProvider {
  getTranslation(): Translation {
    return {
      dateFormat: 'dd/mm/yy',
      clear: 'Wyczyść',
      today: 'Dzisiaj',
      dayNames: [
        'Niedziela',
        'Poniedziałek',
        'Wtorek',
        'Środa',
        'Czwartek',
        'Piątek',
        'Sobota',
      ],
      dayNamesShort: ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob'],
      dayNamesMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pi', 'Sb'],
      monthNames: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
      ],
      weekHeader: 'Tydz.',
      firstDayOfWeek: 1,
      monthNamesShort: [
        'Sty',
        'Lut',
        'Mar',
        'Kwi',
        'Mar',
        'Cze',
        'Lip',
        'Sie',
        'Wrz',
        'Paź',
        'Lis',
        'Gru',
      ],
    };
  }
}
