import { Injectable } from '@angular/core';
import { RouterPath } from '../enums/router-path';
import { RouterLinkProvider } from '../providers/router-link.provider';
import { Router } from '@angular/router';
import { RouterPathParams } from '../types/router-path-params';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { from, Observable } from 'rxjs';
import { blank } from '@core/utils/rx/operators/blank';
import { HtmlAnchorTarget } from '@core/utils/types/html/html-anchor-target';
import { UrlTreeProvider } from '@core/routing/providers/url-tree.provider';

@Injectable({
  providedIn: 'root',
})
export class RouterLinkNavigatorService {
  constructor(
    private readonly routerLinkProvider: RouterLinkProvider,
    private readonly router: Router,
    private readonly urlTreeProvider: UrlTreeProvider,
  ) {}

  navigate(routerPath: RouterPath, params?: Nullable<RouterPathParams>): void {
    void this.router.navigate(
      this.routerLinkProvider.routerLink(routerPath, params),
    );
  }

  navigateInNewTab(
    routerPath: RouterPath,
    params?: Nullable<RouterPathParams>,
  ): void {
    const url = this.router.serializeUrl(
      this.urlTreeProvider.urlTree(routerPath, params),
    );

    window.open(url, HtmlAnchorTarget.BLANK);
  }

  navigateAsync(
    routerPath: RouterPath,
    params?: Nullable<RouterPathParams>,
  ): Observable<void> {
    return from(
      this.router.navigate(
        this.routerLinkProvider.routerLink(routerPath, params),
      ),
    ).pipe(blank());
  }
}
