import { ToastSeverity } from '@shared/toast/enums/toast-severity';

export class Toast {
  constructor(
    readonly title: string,
    readonly content: string,
    readonly severity: ToastSeverity,
    readonly life: number,
  ) {}
}
