import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class GlobalPermitsResponse {
  @jsonMember({ name: 'canCreateInfluencer' })
  readonly canCreateCustomer!: boolean;
  @jsonMember readonly canCreateAdmin!: boolean;
  @jsonMember readonly canCreateOffer!: boolean;
  @jsonMember({ name: 'canCreateTerms' })
  readonly canCreateRegulations!: boolean;
  @jsonMember({ name: 'hasAccessInfluencers' })
  readonly hasAccessCustomers!: boolean;
  @jsonMember readonly hasAccessOffers!: boolean;
  @jsonMember readonly hasAccessAdmins!: boolean;
  @jsonMember readonly hasAccessEmployees!: boolean;
  @jsonMember readonly hasAccessStatistics!: boolean;
  @jsonMember({ name: 'hasAccessTerms' })
  readonly hasAccessRegulations!: boolean;
}
