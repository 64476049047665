import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { OffersApiService } from '@api/offers/offers-api.service';
import { Uuid } from '@core/uuid/uuid';

@Injectable({
  providedIn: 'root',
})
export class FetchSharePreviewOfferContentService {
  constructor(private readonly api: OffersApiService) {}

  content(id: Uuid): Observable<string> {
    return this.api.sharePreview(id).pipe(map(({ content }) => content));
  }
}
