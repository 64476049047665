import { Injectable } from '@angular/core';
import { UserContext } from '@core/auth/models/user-context';
import { MeResponse } from '@api/auth/responses/me.response';
import { PermitsConverter } from '@core/auth/converters/permits.converter';

@Injectable({
  providedIn: 'root',
})
export class UserContextConverter {
  constructor(private readonly permitsConverter: PermitsConverter) {}

  convert(response: MeResponse): UserContext {
    const permits = this.permitsConverter.convert(response.globalPermits);

    return new UserContext(
      response.id,
      response.email,
      response.roles,
      permits,
    );
  }
}
