import { Injectable } from '@angular/core';
import { RegulationDetailsResponse } from '@api/regulation/responses/regulation-details.response';
import { Regulation } from '@features/regulations/models/regulation';

@Injectable({
  providedIn: 'root',
})
export class RegulationFactory {
  fromResponse(response: RegulationDetailsResponse): Regulation {
    return new Regulation(
      response.id,
      response.name,
      response.content,
      response.operations,
    );
  }
}
