import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class RefreshTokenRequest {
  @jsonMember readonly refreshToken: string;

  constructor(refreshToken: string) {
    this.refreshToken = refreshToken;
  }
}
