import { Injectable } from '@angular/core';
import { RouterPathParam } from '../enums/router-path-param';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ActivatedRouteParamNotFoundException } from '../exceptions/activated-route-param-not-found.exception';
import { Uuid } from '@core/uuid/uuid';
import { map, Observable } from 'rxjs';
import { isNumber } from '@core/utils/types/number/is-number';
import { ActivatedRouteTypeParamMismatchException } from '../exceptions/activated-route-type-param-mismatch.exception';
import { isNullable } from '@core/utils/types/nullable/is-nullable';

@Injectable({
  providedIn: 'root',
})
export class ActivatedRouteUtils {
  param(param: RouterPathParam, route: ActivatedRoute): string {
    return this.paramFromSnapshot(param, route.snapshot);
  }

  paramFromSnapshot(
    param: RouterPathParam,
    snapshot: ActivatedRouteSnapshot,
  ): string {
    const result = snapshot.paramMap.get(param);

    if (isNullable(result)) {
      throw new ActivatedRouteParamNotFoundException(
        `The param "${param}" is not found in the route.`,
      );
    }

    return result;
  }

  uuidParamFromSnapshot(
    param: RouterPathParam,
    snapshot: ActivatedRouteSnapshot,
  ): Uuid {
    return this.paramFromSnapshot(param, snapshot) as Uuid;
  }

  numberParamFromSnapshot(
    param: RouterPathParam,
    snapshot: ActivatedRouteSnapshot,
  ): number {
    const result = +this.paramFromSnapshot(param, snapshot);

    if (!isNumber(result)) {
      throw new ActivatedRouteTypeParamMismatchException(
        `The type of param "${param}" is not a number.`,
      );
    }

    return result;
  }

  paramAsync(
    param: RouterPathParam,
    route: ActivatedRoute,
  ): Observable<string> {
    return route.paramMap.pipe(
      map((params) => {
        const result = params.get(param);

        if (isNullable(result)) {
          throw new ActivatedRouteParamNotFoundException(
            `The param "${param}" is not found in the route.`,
          );
        }

        return result;
      }),
    );
  }

  uuidParamAsync(
    param: RouterPathParam,
    route: ActivatedRoute,
  ): Observable<Uuid> {
    return this.paramAsync(param, route) as Observable<Uuid>;
  }

  uuidParam(param: RouterPathParam, route: ActivatedRoute): Uuid {
    return this.param(param, route) as Uuid;
  }

  numberParam(
    param: RouterPathParam,
    route: ActivatedRoute,
  ): Observable<number> {
    return this.paramAsync(param, route).pipe(
      map((value) => {
        const result = +value;

        if (!isNumber(result)) {
          throw new ActivatedRouteTypeParamMismatchException(
            `The type of param "${param}" is not a number.`,
          );
        }

        return result;
      }),
    );
  }
}
