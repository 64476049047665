import { Injectable } from '@angular/core';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { isNullable } from '@core/utils/types/nullable/is-nullable';
import { RequiredMethodCallException } from '@core/exceptions/required-method-call.exception';
import { ConfirmationMessageType } from '@shared/confirmation/enums/confirmation-message-type';
import { ConfirmationMessage } from '@shared/confirmation/models/confirmation-message';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationMessageBuilder {
  private _content: Nullable<string>;
  private _type: Nullable<ConfirmationMessageType>;
  private _title: Nullable<string>;
  private _acceptLabel: Nullable<string>;
  private _rejectLabel: Nullable<string>;

  constructor() {
    this.reset();
  }

  newInstance(): ConfirmationMessageBuilder {
    return new ConfirmationMessageBuilder();
  }

  reset(): this {
    this._content = null;
    this._type = null;
    this._title = null;
    this._acceptLabel = null;
    this._rejectLabel = null;

    return this;
  }

  build(): ConfirmationMessage {
    this.configureDefaultTitle();
    this.configureDefaultType();
    this.configureDefaultAcceptLabel();
    this.configureDefaultRejectLabel();

    this.requireContent();

    const message = new ConfirmationMessage(
      this._title!,
      this._content!,
      this._type!,
      this._acceptLabel!,
      this._rejectLabel!,
    );

    this.reset();

    return message;
  }

  title(title: string): this {
    this._title = title;

    return this;
  }

  content(content: string): this {
    this._content = content;

    return this;
  }

  type(type: ConfirmationMessageType): this {
    this._type = type;

    return this;
  }

  acceptLabel(label: string): this {
    this._acceptLabel = label;

    return this;
  }

  rejectLabel(label: string): this {
    this._rejectLabel = label;

    return this;
  }

  private configureDefaultTitle(): void {
    if (!isNullable(this._title)) {
      return;
    }

    this.title('Potwierdzenie');
  }

  private configureDefaultType(): void {
    if (!isNullable(this._type)) {
      return;
    }

    this.type(ConfirmationMessageType.TEXT);
  }

  private configureDefaultAcceptLabel(): void {
    if (!isNullable(this._acceptLabel)) {
      return;
    }

    this.acceptLabel('Tak');
  }

  private configureDefaultRejectLabel(): void {
    if (!isNullable(this._rejectLabel)) {
      return;
    }

    this.rejectLabel('Nie');
  }

  private requireContent(): void {
    if (!isNullable(this._content)) {
      return;
    }

    throw new RequiredMethodCallException('content');
  }
}
