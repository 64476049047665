import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { blank } from '@core/utils/rx/operators/blank';
import { AuthService } from '@core/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInitializer {
  constructor(private readonly auth: AuthService) {}

  initialize(): Observable<void> {
    return this.auth.fetchUserContext().pipe(
      catchError(() => of(void 0)),
      blank(),
    );
  }
}
