import { Uuid } from '@core/uuid/uuid';
import { RegulationContextOperations } from '@features/regulations/models/regulation-context-operations';

export class Regulation {
  constructor(
    readonly id: Uuid,
    readonly name: string,
    readonly content: string,
    readonly operations: RegulationContextOperations,
  ) {}
}
