import { Injectable } from '@angular/core';
import { FilePreviewResponse } from '@api/files/responses/file-preview.response';
import { ImageFile } from '@features/files/models/image-file';
import { FileUploadResponse } from '@api/files/responses/file-upload.response';

@Injectable({
  providedIn: 'root',
})
export class ImageFileFactory {
  fromPreviewResponse(response: FilePreviewResponse): ImageFile {
    return new ImageFile(response.id, response.previewUrl);
  }

  fromUploadResponse(response: FileUploadResponse): ImageFile {
    return new ImageFile(response.id, response.previewUrl);
  }
}
