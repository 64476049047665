import { Component } from '@angular/core';
import { PrimengTranslationProvider } from '@core/config/primeng-translation.provider';
import { PrimeNGConfig } from 'primeng/api';
import { ConfirmationDialogComponent } from '@shared/confirmation/components/confirmation-dialog/confirmation-dialog.component';
import { ToastComponent } from '@shared/toast/components/toast/toast.component';
import { SpinnerComponent } from '@shared/spinner/spinner.component';
import { LoadingIndicatorComponent } from '@scaffolds/fragments/loading-indicator/loading-indicator.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    ConfirmationDialogComponent,
    ToastComponent,
    SpinnerComponent,
    LoadingIndicatorComponent,
    RouterOutlet,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly primengTranslation: PrimengTranslationProvider,
    private readonly primengConfig: PrimeNGConfig,
  ) {
    this.configurePrimeng();
  }

  private configurePrimeng(): void {
    this.primengConfig.setTranslation(this.primengTranslation.getTranslation());
  }
}
