import { Injectable } from '@angular/core';
import { RouterLinkProvider } from './router-link.provider';
import { Params, Router, UrlTree } from '@angular/router';
import { RouterPath } from '../enums/router-path';
import { Nullable } from '@core/utils/types/nullable/nullable';

@Injectable({
  providedIn: 'root',
})
export class UrlTreeProvider {
  constructor(
    private readonly routerLinkProvider: RouterLinkProvider,
    private readonly router: Router,
  ) {}

  urlTree(path: RouterPath, params?: Nullable<Params>): UrlTree {
    return this.router.createUrlTree(
      this.routerLinkProvider.routerLink(path, params),
    );
  }
}
