import { Injectable } from '@angular/core';
import { SignInRequest } from './requests/sign-in.request';
import { map, Observable } from 'rxjs';
import { SignInResponse } from './responses/sign-in.response';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { MeResponse } from './responses/me.response';
import { HttpContextBuilder } from '@core/http/context/builders/http-context-builder.service';
import { RefreshTokenResponse } from './responses/refresh-token.response';
import { RefreshTokenRequest } from './requests/refresh-token.request';
import { ApiHelperService } from '@api/core/services/api-helper.service';
import { ActivationRequest } from '@api/auth/requests/activation.request';
import { blank } from '@core/utils/rx/operators/blank';
import { ApiParams } from '@environments/enums/api-params';
import { Uuid } from '@core/uuid/uuid';
import { PasswordRecoverRequest } from '@api/auth/requests/password-recover.request';
import { PasswordResetRequest } from './requests/password-reset.request';
import { ApiUrlFactory } from '@api/factories/api-url.factory';

const { AUTH } = environment.api;

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly helper: ApiHelperService,
    private readonly contextBuilder: HttpContextBuilder,
    private readonly apiUrlFactory: ApiUrlFactory,
  ) {}

  signIn(request: SignInRequest): Observable<SignInResponse> {
    const { SIGN_IN, HOST_URL } = AUTH;
    const url = this.apiUrlFactory.simple(HOST_URL, SIGN_IN);
    const body = this.helper.requestToBody(request, SignInRequest);
    const context = this.contextBuilder
      .skipByRefreshToken()
      .skipByAuthToken()
      .build();

    return this.http
      .post(url, body, { context })
      .pipe(map(this.helper.bodyToResponse(SignInResponse)));
  }

  recoverPassword(request: PasswordRecoverRequest): Observable<void> {
    const { RECOVER_PASSWORD, HOST_URL } = AUTH;
    const url = this.apiUrlFactory.simple(HOST_URL, RECOVER_PASSWORD);
    const body = this.helper.requestToBody(request, PasswordRecoverRequest);
    const context = this.contextBuilder
      .skipByRefreshToken()
      .skipByAuthToken()
      .build();

    return this.http.post(url, body, { context }).pipe(blank());
  }

  resetPassword(request: PasswordResetRequest, token: Uuid): Observable<void> {
    const { RESET_PASSWORD, HOST_URL } = AUTH;
    const url = this.apiUrlFactory.withParams(HOST_URL, RESET_PASSWORD, {
      [ApiParams.ACTIVATION_TOKEN]: token,
    });
    const body = this.helper.requestToBody(request, PasswordResetRequest);
    const context = this.contextBuilder
      .skipByRefreshToken()
      .skipByAuthToken()
      .build();

    return this.http.post(url, body, { context }).pipe(blank());
  }

  me(): Observable<MeResponse> {
    const { ME, HOST_URL } = AUTH;
    const url = this.apiUrlFactory.simple(HOST_URL, ME);

    return this.http.get(url).pipe(map(this.helper.bodyToResponse(MeResponse)));
  }

  refresh(request: RefreshTokenRequest): Observable<RefreshTokenResponse> {
    const { REFRESH, HOST_URL } = AUTH;
    const url = this.apiUrlFactory.simple(HOST_URL, REFRESH);
    const body = this.helper.requestToBody(request, RefreshTokenRequest);
    const context = this.contextBuilder
      .skipByRefreshToken()
      .skipByAuthToken()
      .build();

    return this.http
      .post(url, body, { context })
      .pipe(map(this.helper.bodyToResponse(RefreshTokenResponse)));
  }

  activate(request: ActivationRequest, token: Uuid): Observable<void> {
    const { ACTIVATE, HOST_URL } = AUTH;
    const url = this.apiUrlFactory.withParams(HOST_URL, ACTIVATE, {
      [ApiParams.ACTIVATION_TOKEN]: token,
    });
    const body = this.helper.requestToBody(request, ActivationRequest);
    const context = this.contextBuilder
      .skipByRefreshToken()
      .skipByAuthToken()
      .build();

    return this.http.post(url, body, { context }).pipe(blank());
  }
}
