import { dateFormat } from '@core/locale/utils/date-format';
import { isNullable } from '@core/utils/types/nullable/is-nullable';
import { YYYY_MM_DD } from '@core/locale/consts/date-format';
import { Nullable } from '@core/utils/types/nullable/nullable';

export const dateSerializer =
  (format: string = YYYY_MM_DD) =>
  (value: Nullable<Date>): string | null => {
    if (isNullable(value)) {
      return null;
    }

    return dateFormat(value, format);
  };
