import { jsonMember, jsonObject } from 'typedjson';
import { Uuid } from '@core/uuid/uuid';
import { RegulationDetailsOperationsResponse } from '@api/regulation/responses/regulation-details-operations.response';

@jsonObject
export class RegulationDetailsResponse {
  @jsonMember readonly id!: Uuid;
  @jsonMember readonly name!: string;
  @jsonMember readonly content!: string;
  @jsonMember({
    name: 'contextOperations',
  })
  readonly operations!: RegulationDetailsOperationsResponse;
}
