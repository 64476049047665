import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { once } from '@core/utils/rx/operators/once';
import { ConfirmationMessage } from '../models/confirmation-message';
import { ConfirmationResult } from '@shared/confirmation/models/confirmation-result';
import { ConfirmationMessageBuilder } from '@shared/confirmation/builders/confirmation-message.builder';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  private readonly showSource$ = new Subject<ConfirmationMessage>();
  private readonly closeSource$ = new Subject<ConfirmationResult>();

  constructor(private readonly builder: ConfirmationMessageBuilder) {}

  get show$(): Observable<ConfirmationMessage> {
    return this.showSource$.asObservable();
  }

  show(
    factory: (builder: ConfirmationMessageBuilder) => ConfirmationMessage,
  ): Observable<ConfirmationResult> {
    this.showSource$.next(factory(this.builder.newInstance()));

    return this.closeSource$.asObservable().pipe(once());
  }

  close(result: ConfirmationResult): void {
    this.closeSource$.next(result);
  }
}
