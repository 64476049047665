import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';
import { SkipByAuthInterceptor } from '../tokens/skip-by-auth-interceptor';
import { SkipByRefreshInterceptor } from '../tokens/skip-by-refresh-interceptor';
import { SkipByLoadingInterceptor } from '@core/http/context/tokens/skip-by-loading-interceptor';

@Injectable({
  providedIn: 'root',
})
export class HttpContextBuilder {
  private context = new HttpContext();

  constructor() {
    this.reset();
  }

  reset(): this {
    this.context = new HttpContext();

    return this;
  }

  skipByAuthToken(): this {
    this.context.set(SkipByAuthInterceptor, true);

    return this;
  }

  skipByRefreshToken(): this {
    this.context.set(SkipByRefreshInterceptor, true);

    return this;
  }

  skipByLoadingToken(): this {
    this.context.set(SkipByLoadingInterceptor, true);

    return this;
  }

  build(): HttpContext {
    const context = this.context;

    this.reset();

    return context;
  }
}
