<p-dialog
  appSimpleDialog
  appCustomCloseEvent
  styleClass="confirmation-dialog"
  [customCloseEvent]="customCloseEvent"
  [(visible)]="visible"
>
  <ng-template pTemplate="header">
    <ng-container *ngIf="visible">
      <div class="confirmation__title">{{ title }}</div>
    </ng-container>
  </ng-template>

  <ng-template pTemplate="content">
    <ng-container *ngIf="visible">
      <div class="confirmation__content">
        <ng-container [ngSwitch]="true">
          <ng-container *ngSwitchCase="isHTMLContent">
            <div [innerHTML]="content"></div>
          </ng-container>

          <ng-container *ngSwitchCase="isTextContent">
            <div>{{ content }}</div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>

  <ng-template pTemplate="footer">
    <ng-container *ngIf="visible">
      <div class="row gx-2">
        <div class="ms-auto col-auto">
          <button
            pButton
            type="button"
            class="p-button-outlined px-4"
            [label]="rejectLabel"
            (click)="handleRejectClickEvent()"
          ></button>
        </div>

        <div class="col-auto">
          <button
            pButton
            autofocus
            type="button"
            class="px-4"
            [label]="acceptLabel"
            (click)="handleAcceptClickEvent()"
          ></button>
        </div>
      </div>
    </ng-container>
  </ng-template>
</p-dialog>
