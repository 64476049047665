import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { SkipByAuthInterceptor } from '@core/http/context/tokens/skip-by-auth-interceptor';
import { HttpRequestHeaderService } from '../services/http-request-header.service';

@Injectable()
export class HttpAuthTokenInterceptor<Body> implements HttpInterceptor {
  constructor(private readonly requestHeader: HttpRequestHeaderService) {}

  intercept(
    request: HttpRequest<Body>,
    next: HttpHandler,
  ): Observable<HttpEvent<Body>> {
    if (this.isSkippedRequest(request)) {
      return next.handle(request);
    }

    try {
      return this.requestHeader
        .appendAuthorizationHeader(request)
        .pipe(switchMap((request) => next.handle(request)));
    } catch (error) {
      return next.handle(request);
    }
  }

  private isSkippedRequest(request: HttpRequest<Body>): boolean {
    return request.context.has(SkipByAuthInterceptor);
  }
}
