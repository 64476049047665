import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '@shared/toast/services/toast.service';
import { ToastToPrimengMessageConverter } from '@shared/toast/converters/toast-to-primeng-message.converter';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule, ToastModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly message: MessageService,
    private readonly toast: ToastService,
    private readonly converter: ToastToPrimengMessageConverter,
  ) {}

  ngOnInit(): void {
    this.listenToToastShow();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private listenToToastShow(): void {
    this.toast.show$.pipe(takeUntil(this.destroy$)).subscribe((toast) => {
      this.message.add(this.converter.convert(toast));
    });
  }
}
