import { Injectable } from '@angular/core';
import { Uuid } from '@core/uuid/uuid';
import { map, Observable } from 'rxjs';
import { RegulationsApiService } from '@api/regulation/regulations-api.service';

@Injectable({
  providedIn: 'root',
})
export class FetchPreviewRegulationContentService {
  constructor(private readonly api: RegulationsApiService) {}

  content(id: Uuid): Observable<string> {
    return this.api.preview(id).pipe(map(({ content }) => content));
  }
}
