import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { Subject, takeUntil } from 'rxjs';
import { CustomCloseEventDirective } from '@shared/dialog/directives/custom-close-event.directive';
import { ButtonModule } from 'primeng/button';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { isNullable } from '@core/utils/types/nullable/is-nullable';
import { ConfirmationMessageType } from '@shared/confirmation/enums/confirmation-message-type';
import { ConfirmationDialogService } from '@shared/confirmation/services/confirmation-dialog.service';
import { ConfirmationMessage } from '@shared/confirmation/models/confirmation-message';
import { ConfirmationResultType } from '@shared/confirmation/enums/confirmation-result-type';
import { ConfirmationResult } from '@shared/confirmation/models/confirmation-result';
import { SimpleDialogDirective } from '@shared/dialog/directives/simple-dialog.directive';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    CustomCloseEventDirective,
    ButtonModule,
    SimpleDialogDirective,
  ],
  providers: [ConfirmationService],
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {
  visible: boolean = false;
  acceptLabel: Nullable<string>;
  rejectLabel: Nullable<string>;
  title: Nullable<string>;
  content: Nullable<string>;
  type: Nullable<ConfirmationMessageType>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly confirmationDialog: ConfirmationDialogService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  customCloseEvent = (): void => this.closeWithReject();

  get isHTMLContent(): boolean {
    if (isNullable(this.type)) {
      return false;
    }

    return this.type === ConfirmationMessageType.HTML;
  }

  get isTextContent(): boolean {
    if (isNullable(this.type)) {
      return false;
    }

    return this.type === ConfirmationMessageType.TEXT;
  }

  ngOnInit(): void {
    this.listenToDialogShow();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  handleRejectClickEvent(): void {
    this.closeWithReject();
  }

  handleAcceptClickEvent(): void {
    this.closeWithAccept();
  }

  private listenToDialogShow(): void {
    this.confirmationDialog.show$
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmation: ConfirmationMessage) => {
        this.configure(confirmation);
        this.showDialogOnUI();
        this.detectChanges();
      });
  }

  private configure(confirmation: ConfirmationMessage): void {
    const { acceptLabel, rejectLabel, title, content, type } = confirmation;

    this.acceptLabel = acceptLabel;
    this.rejectLabel = rejectLabel;
    this.title = title;
    this.content = content;
    this.type = type;
  }

  private closeWithAccept(): void {
    this.close(new ConfirmationResult(ConfirmationResultType.ACCEPT));
  }

  private closeWithReject(): void {
    this.close(new ConfirmationResult(ConfirmationResultType.REJECT));
  }

  private close(result: ConfirmationResult): void {
    this.confirmationDialog.close(result);

    this.hideDialogOnUI();
  }

  private showDialogOnUI(): void {
    this.visible = true;
  }

  private hideDialogOnUI(): void {
    this.visible = false;
  }

  private detectChanges(): void {
    this.changeDetectorRef.detectChanges();
  }
}
