import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class PasswordRecoverRequest {
  @jsonMember readonly email!: string;

  constructor(email: string) {
    this.email = email;
  }
}
