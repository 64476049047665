import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class CreateRegulationRequest {
  @jsonMember readonly name!: string;
  @jsonMember readonly content!: string;

  constructor(name: string, content: string) {
    this.name = name;
    this.content = content;
  }
}
