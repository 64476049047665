import { Injectable } from '@angular/core';
import { Constructor } from '../types/constructor';
import { JsonTypes } from '../types/json-types';
import { TypedJSON } from 'typedjson';

@Injectable({
  providedIn: 'root',
})
export class JsonTransformerService {
  toObject<Object, Plain>(json: Plain, type: Constructor<Object>): Object {
    return TypedJSON.parse(json, type);
  }

  toArray<Object, Plain>(json: Plain[], type: Constructor<Object>): Object[] {
    return TypedJSON.parseAsArray(json, type);
  }

  toJson<Object>(object: Object, type: Constructor<Object>): JsonTypes {
    return TypedJSON.toPlainJson(object, type);
  }

  toJsonArray<Object>(
    object: Object[],
    type: Constructor<Object>,
  ): JsonTypes[] {
    return TypedJSON.toPlainArray(object, type);
  }

  stringify<Object>(value: Object, type: Constructor<Object>): string {
    return TypedJSON.stringify(value, type);
  }

  stringifyAsArray<Object>(value: Object[], type: Constructor<Object>): string {
    return TypedJSON.stringifyAsArray(value, type);
  }

  parse<Object>(value: string, type: Constructor<Object>): Object {
    return TypedJSON.parse(value, type);
  }

  parseAsArray<Object>(value: string, type: Constructor<Object>): Object[] {
    return TypedJSON.parseAsArray(value, type);
  }
}
