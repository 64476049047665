import { Injectable } from '@angular/core';
import { catchError, map, Observable, switchMap, tap, throwError } from 'rxjs';
import { UserContext } from '@core/auth/models/user-context';
import { AuthContext } from '@core/auth/models/auth-context';
import { once } from '@core/utils/rx/operators/once';
import { AuthContextStorage } from '@core/auth/storages/auth-context.storage';
import { AuthApiService } from '@api/auth/auth-api.service';
import { SignInRequest } from '@api/auth/requests/sign-in.request';
import { UserContextStorage } from '@core/auth/storages/user-context.storage';
import { RefreshTokenRequest } from '@api/auth/requests/refresh-token.request';
import { isNullable } from '@core/utils/types/nullable/is-nullable';
import { SignInProcessException } from '@core/auth/exceptions/sign-in-process.exception';
import { RefreshTokenConverter } from '@core/auth/converters/refresh-token.converter';
import { UserContextConverter } from '@core/auth/converters/user-context.converter';
import { HttpErrorResponseHandler } from '@api/core/handlers/http-error-response.handler';
import { AuthErrorResponse } from '@api/core/responses/auth-error.response';
import { Uuid } from '@core/uuid/uuid';
import { ActivationRequest } from '@api/auth/requests/activation.request';
import { ActivationException } from '@core/auth/exceptions/activation.exception';
import { PasswordRecoverRequest } from '@api/auth/requests/password-recover.request';
import { PasswordRecoverException } from '../exceptions/password-recover.exception';
import { PasswordResetRequest } from '@api/auth/requests/password-reset.request';
import { ValidationErrorResponse } from '@api/core/responses/validation-error.response';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { PasswordTokenExpiredException } from '@core/auth/exceptions/password-token-expired.exception';
import { PasswordResetException } from '@core/auth/exceptions/password-reset.exception';
import { ActivationTokenExpiredException } from '@core/auth/exceptions/activation-token-expired.exception';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly authApi: AuthApiService,
    private readonly httpErrorHandler: HttpErrorResponseHandler,
    private readonly authContextStorage: AuthContextStorage,
    private readonly userContextStorage: UserContextStorage,
    private readonly refreshTokenConverter: RefreshTokenConverter,
    private readonly userContextConverter: UserContextConverter,
  ) {}

  signIn(email: string, password: string): Observable<UserContext> {
    const request = new SignInRequest(email, password);

    return this.authApi.signIn(request).pipe(
      catchError(this.httpErrorHandler.handleAuthError()),
      catchError(({ message }: AuthErrorResponse) =>
        throwError(
          () =>
            new SignInProcessException(message ?? 'Wystąpił nieznany błąd.'),
        ),
      ),
      map(({ token, refreshToken }) => new AuthContext(token, refreshToken)),
      tap((context) => this.authContextStorage.store(context)),
      switchMap(() => this.fetchUserContext()),
    );
  }

  signOut(): Observable<void> {
    return new Observable<void>((subject) => {
      this.authContextStorage.clear();
      this.userContextStorage.clear();

      subject.next();
      subject.complete();
    });
  }

  activate(password: string, token: Uuid): Observable<void> {
    const request = new ActivationRequest(password);

    return this.authApi.activate(request, token).pipe(
      catchError((response: HttpErrorResponse) => {
        const { status } = response;

        return throwError(() => {
          if (status === HttpStatusCode.NotFound) {
            return new ActivationTokenExpiredException(
              'Link aktywacyjny wygasł lub jest niepoprawny.',
            );
          }

          return new ActivationException('Nie udało się aktywować konta.');
        });
      }),
    );
  }

  resetPassword(password: string, token: Uuid): Observable<void> {
    const request = new PasswordResetRequest(password);

    return this.authApi.resetPassword(request, token).pipe(
      catchError((response: HttpErrorResponse) => {
        const { status } = response;

        return throwError(() => {
          if (status === HttpStatusCode.NotFound) {
            return new PasswordTokenExpiredException(
              'Link aktywacyjny wygasł lub jest niepoprawny.',
            );
          }

          return new PasswordResetException('Nie udało się zresetować hasła.');
        });
      }),
    );
  }

  recoverPassword(email: string): Observable<void> {
    const request = new PasswordRecoverRequest(email);

    return this.authApi.recoverPassword(request).pipe(
      catchError(this.httpErrorHandler.handleValidationError()),
      catchError(({ details }: ValidationErrorResponse) => {
        const [first] = details;

        return throwError(
          () =>
            new PasswordRecoverException(
              first.title ?? 'Wystąpił nieznany błąd.',
            ),
        );
      }),
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.userContextStorage
      .fetch()
      .pipe(map((context) => !isNullable(context)))
      .pipe(once());
  }

  refreshToken(): Observable<AuthContext> {
    const refreshToken = this.authContextStorage.fetchRefreshToken();
    const request = new RefreshTokenRequest(refreshToken);

    return this.authApi.refresh(request).pipe(
      map((response) => this.refreshTokenConverter.convert(response)),
      tap((context) => this.authContextStorage.store(context)),
    );
  }

  fetchUserContext(): Observable<UserContext> {
    return this.authApi.me().pipe(
      map((response) => this.userContextConverter.convert(response)),
      tap((context) => this.userContextStorage.store(context)),
    );
  }

  fetchAuthContext(): Observable<AuthContext> {
    return new Observable<AuthContext>((subject) => {
      try {
        const context = this.authContextStorage.fetch();

        subject.next(context);
        subject.complete();
      } catch (error) {
        subject.error(error);
      }
    });
  }
}
