import { Injectable } from '@angular/core';
import { CollectionRequest } from '../requests/collection.request';
import { CollectionResponse } from '../responses/collection.response';
import { CollectionSortRequest } from '../requests/collection-sort.request';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { isNullable } from '@core/utils/types/nullable/is-nullable';
import { JsonTransformerService } from '@core/json/services/json-transformer.service';
import { JsonTypes } from '@core/json/types/json-types';
import { Constructor } from '@core/json/types/constructor';

@Injectable({
  providedIn: 'root',
})
export class ApiHelperService {
  constructor(private readonly jsonTransformer: JsonTransformerService) {}

  requestToBody<Request>(
    request: Request,
    type: Constructor<Request>,
  ): JsonTypes {
    return this.jsonTransformer.toJson(request, type);
  }

  requestToQueryParams<Request>(
    request: Request,
    requestType: Constructor<Request>,
  ): Object {
    return this.preparePlainObject(request, requestType) ?? {};
  }

  collectionRequestToQueryParams<FilterRequest>(
    request: CollectionRequest<FilterRequest>,
    filterRequestType: Constructor<FilterRequest>,
  ): Object {
    const { filter, page, order, limit, inSinglePage } = request;

    return {
      filter: this.preparePlainObject(filter, filterRequestType),
      order: this.preparePlainOrderObject(order),
      page,
      inSinglePage,
      limit,
    };
  }

  emptyBody(): {} {
    return {};
  }

  bodyToResponse<Response>(
    type: Constructor<Response>,
  ): (json: JsonTypes) => Response {
    return (json: JsonTypes) => this.jsonTransformer.toObject(json, type);
  }

  bodyToCollectionResponse<CollectionItemResponse>(
    collectionItemResponseType: Constructor<CollectionItemResponse>,
  ): (json: JsonTypes) => CollectionResponse<CollectionItemResponse> {
    return (json: JsonTypes) => {
      const object = this.jsonTransformer.toObject(json, CollectionResponse);
      const { metadata, collection } = object;

      return new CollectionResponse<CollectionItemResponse>(
        metadata,
        this.jsonTransformer.toArray(collection, collectionItemResponseType),
      );
    };
  }

  private preparePlainObject<ObjectType>(
    object: Nullable<ObjectType>,
    requestType: Constructor<ObjectType>,
  ): Nullable<Object> {
    if (isNullable(object)) {
      return object;
    }

    return this.jsonTransformer.toJson(object, requestType);
  }

  private preparePlainOrderObject(
    order: Nullable<CollectionSortRequest[]>,
  ): Nullable<Object> {
    if (isNullable(order)) {
      return order;
    }

    return order.reduce((object, { field, order }) => {
      return { ...object, [field]: order };
    }, {});
  }
}
