import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserContext } from '@core/auth/models/user-context';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { once } from '@core/utils/rx/operators/once';

@Injectable({
  providedIn: 'root',
})
export class UserContextStorage {
  private readonly userContextSource$ = new BehaviorSubject<
    Nullable<UserContext>
  >(null);

  clear(): void {
    this.userContextSource$.next(null);
  }

  fetch(): Observable<Nullable<UserContext>> {
    return this.userContextSource$.asObservable().pipe(once());
  }

  store(context: UserContext): void {
    this.userContextSource$.next(context);
  }
}
