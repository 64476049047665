import { jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ValidationErrorDetailsResponse {
  @jsonMember readonly propertyPath!: string;
  @jsonMember readonly title!: string;
  @jsonMember readonly parameters!: {
    [key: string]: string;
  };
  @jsonMember readonly type?: string;
}
