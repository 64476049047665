import { Uuid } from '@core/uuid/uuid';
import { Role } from '@core/auth/enums/role';
import { UserPermissions } from '@core/auth/models/user-permissions';

export class UserContext {
  constructor(
    readonly id: Uuid,
    readonly email: string,
    readonly roles: Role[],
    readonly permissions: UserPermissions,
  ) {}
}
