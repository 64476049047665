import { Injectable } from '@angular/core';
import { RefreshTokenResponse } from '@api/auth/responses/refresh-token.response';
import { AuthContext } from '@core/auth/models/auth-context';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenConverter {
  convert(response: RefreshTokenResponse): AuthContext {
    const { token, refreshToken } = response;

    return new AuthContext(token, refreshToken);
  }
}
