import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { RouterLinkNavigatorService } from '@core/routing/services/router-link-navigator.service';
import { ActivatedRouteUtils } from '@core/routing/utils/activated-route.utils';
import { catchError, EMPTY } from 'rxjs';
import { RouterPath } from '@core/routing/enums/router-path';
import { Regulation } from '@features/regulations/models/regulation';
import { RouterPathParam } from '@core/routing/enums/router-path-param';
import { FetchRegulationService } from '@features/regulations/services/fetch-regulation.service';

export const RegulationResolver: ResolveFn<Regulation> = (
  route,
  state,
  fetch = inject(FetchRegulationService),
  navigator = inject(RouterLinkNavigatorService),
  utils = inject(ActivatedRouteUtils),
) => {
  const id = utils.uuidParamFromSnapshot(RouterPathParam.REGULATION_ID, route);
  const navigateToNotFoundPage = (): void =>
    navigator.navigate(RouterPath.NOT_FOUND);

  return fetch.byId(id).pipe(
    catchError(() => {
      navigateToNotFoundPage();

      return EMPTY;
    }),
  );
};
