import { Injectable } from '@angular/core';
import { OfferContextOperations } from '@features/offers/models/offer-context-operations';
import { OfferDetailsOperationsResponse } from '@api/offers/responses/offer-details-operations.response';

@Injectable({
  providedIn: 'root',
})
export class OfferContextOperationsFactory {
  fromResponse(
    response: OfferDetailsOperationsResponse,
  ): OfferContextOperations {
    const { canDelete, canUpdate, canView, canSharePreview } = response;

    return new OfferContextOperations(
      canUpdate,
      canDelete,
      canView,
      canSharePreview,
    );
  }
}
