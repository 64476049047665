import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { Dialog } from 'primeng/dialog';
import { isNullable } from '@core/utils/types/nullable/is-nullable';

@Directive({
  selector: '[appCustomCloseEvent]',
  standalone: true,
})
export class CustomCloseEventDirective implements OnChanges {
  @Input() customCloseEvent: Nullable<(event: Event) => void>;

  private readonly originalCloseEvent: (event: Event) => void;

  constructor(private readonly dialog: Dialog) {
    this.originalCloseEvent = this.dialog.close;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { customCloseEvent } = changes;

    if (customCloseEvent) {
      this.applyCustomCloseEvent();
    }
  }

  private applyCustomCloseEvent(): void {
    if (isNullable(this.customCloseEvent)) {
      this.dialog.close = this.originalCloseEvent;

      return;
    }

    this.dialog.close = this.customCloseEvent;
  }
}
