export enum RouterPath {
  ROOT,
  SIGN_IN,
  SIGN_OUT,
  ACTIVATION,
  RESET_PASSWORD,
  RECOVER_PASSWORD,
  CUSTOMERS,
  CUSTOMER_PREVIEW_WALLET,
  PAINTER_PREVIEW_WALLET,
  OFFERS,
  CREATE_OFFER,
  UPDATE_OFFER,
  SHARE_PREVIEW_OFFER,
  COMMERCIAL_OFFERS,
  CREATE_COMMERCIAL_OFFER,
  UPDATE_COMMERCIAL_OFFER,
  SHARE_PREVIEW_COMMERCIAL_OFFER,
  ADMINS,
  EMPLOYEES,
  STATISTICS,
  SUBSCRIPTION_STATISTICS,
  REGULATIONS,
  CREATE_REGULATION,
  UPDATE_REGULATION,
  PREVIEW_REGULATION,
  APPLICATIONS,
  PAINTERS,
  REGISTRATION_MESSAGE,
  NOT_FOUND,
}
