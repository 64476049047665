import { Injectable } from '@angular/core';
import { Nullable } from '@core/utils/types/nullable/nullable';
import { isNullable } from '@core/utils/types/nullable/is-nullable';
import { RequiredMethodCallException } from '@core/exceptions/required-method-call.exception';
import { ToastSeverity } from '../enums/toast-severity';
import { Toast } from '@shared/toast/models/toast';

@Injectable({
  providedIn: 'root',
})
export class ToastBuilder {
  private _title: Nullable<string>;
  private _content: Nullable<string>;
  private _severity: Nullable<ToastSeverity>;
  private _life: Nullable<number>;

  constructor() {
    this.reset();
  }

  newInstance(): ToastBuilder {
    return new ToastBuilder();
  }

  reset(): this {
    this._title = null;
    this._content = null;
    this._severity = null;
    this._life = null;

    return this;
  }

  buildSuccess(content: string): Toast {
    return this.initSuccess(content).build();
  }

  buildFailure(content: string): Toast {
    return this.initFailure(content).build();
  }

  buildWarning(content: string): Toast {
    return this.initWarning(content).build();
  }

  buildInfo(content: string): Toast {
    return this.initInfo(content).build();
  }

  initSuccess(content: string): this {
    return this.severity(ToastSeverity.SUCCESS)
      .content(content)
      .title('Sukces');
  }

  initWarning(content: string): this {
    return this.severity(ToastSeverity.WARNING).content(content).title('Uwaga');
  }

  initFailure(content: string): this {
    return this.severity(ToastSeverity.FAILURE).content(content).title('Błąd');
  }

  initInfo(content: string): this {
    return this.severity(ToastSeverity.INFO)
      .content(content)
      .title('Komunikat');
  }

  life(life: number): this {
    this._life = life;

    return this;
  }

  title(title: string): this {
    this._title = title;

    return this;
  }

  content(content: string): this {
    this._content = content;

    return this;
  }

  severity(severity: ToastSeverity): this {
    this._severity = severity;

    return this;
  }

  build(): Toast {
    this.configureDefaultLife();

    this.requireSeverity();
    this.requireContent();

    const toast = new Toast(
      this._title!,
      this._content!,
      this._severity!,
      this._life!,
    );

    this.reset();

    return toast;
  }

  private requireSeverity(): void {
    if (!isNullable(this._severity)) {
      return;
    }

    throw new RequiredMethodCallException('severity');
  }

  private requireContent(): void {
    if (!isNullable(this._content)) {
      return;
    }

    throw new RequiredMethodCallException('content');
  }

  private configureDefaultLife(): void {
    if (!isNullable(this._life)) {
      return;
    }

    this.life(5000);
  }
}
