import { AnyT, jsonMember, jsonObject } from 'typedjson';
import { HttpStatusCode } from '@angular/common/http';

@jsonObject
export class CommonErrorResponse<StatusCode = never> {
  @jsonMember readonly type!: string;
  @jsonMember readonly title!: string;
  @jsonMember readonly status!: HttpStatusCode;
  @jsonMember({ name: 'detail' }) readonly message!: string;
  @jsonMember(AnyT) readonly code?: StatusCode;
}
