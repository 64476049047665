import { ApiParams } from '@environments/enums/api-params';

export const AUTH = {
  HOST_URL: 'http://api.ppg.loc/admin',
  REFRESH: 'auth/refresh',
  SIGN_IN: 'auth/login',
  ME: 'auth/me',
  ACTIVATE: `auth/activate/${ApiParams.ACTIVATION_TOKEN}`,
  RECOVER_PASSWORD: 'auth/password/recover',
  RESET_PASSWORD: `auth/password/reset/${ApiParams.ACTIVATION_TOKEN}`,
};

export const NOTIFICATIONS = {
  HOST_URL: 'http://api.ppg.loc/admin',
  SEND: 'notifications',
};

export const CUSTOMERS = {
  HOST_URL: 'http://api.ppg.loc/admin',
  CREATE: 'influencers',
  UPDATE: `influencers/${ApiParams.CUSTOMER_ID}`,
  REMOVE: `influencers/${ApiParams.CUSTOMER_ID}`,
  DETAILS: `influencers/${ApiParams.CUSTOMER_ID}`,
  RESTORE: `influencers/${ApiParams.CUSTOMER_ID}/restore`,
  BLOCK: `influencers/${ApiParams.CUSTOMER_ID}/block`,
  UNBLOCK: `influencers/${ApiParams.CUSTOMER_ID}/unblock`,
  INVITE_TO_REGISTER: `influencers/${ApiParams.CUSTOMER_ID}/invite-to-register`,
  LIST: 'influencers',
  WALLET: {
    LIST: `influencers/${ApiParams.CUSTOMER_ID}/wallet/operations`,
    BALANCE: `influencers/${ApiParams.CUSTOMER_ID}/wallet/balance`,
  },
};

export const OFFERS = {
  HOST_URL: 'http://api.ppg.loc/admin',
  LIST: 'offers',
  CREATE: 'offers',
  DETAILS: `offers/${ApiParams.OFFER_ID}`,
  UPDATE: `offers/${ApiParams.OFFER_ID}`,
  REMOVE: `offers/${ApiParams.OFFER_ID}`,
  SHARE_PREVIEW: `offers/${ApiParams.OFFER_ID}/share-preview`,
};

export const ADMINS = {
  HOST_URL: 'http://api.ppg.loc/admin',
  LIST: 'admins',
  CREATE: 'admins',
  BLOCK: `admins/${ApiParams.ADMIN_ID}/block`,
  UNBLOCK: `admins/${ApiParams.ADMIN_ID}/unblock`,
  RESET_ACTIVATION_TOKEN: `admins/${ApiParams.ADMIN_ID}/activation-token/reset`,
};

export const FILES = {
  HOST_URL: 'http://api.ppg.loc/admin',
  UPLOAD: 'files/upload',
  DETAILS: `files/${ApiParams.FILE_ID}`,
  PREVIEW: `files/${ApiParams.FILE_ID}/preview`,
};

export const EMPLOYEES = {
  HOST_URL: 'http://api.ppg.loc/admin',
  LIST: 'employees',
};

export const STATISTICS = {
  HOST_URL: 'http://api.ppg.loc/admin',
  SUBSCRIPTIONS: 'statistics/subscriptions',
};

export const REGULATIONS = {
  HOST_URL: 'http://api.ppg.loc/admin',
  LIST: 'terms',
  CREATE: 'terms',
  DETAILS: `terms/${ApiParams.REGULATION_ID}`,
  UPDATE: `terms/${ApiParams.REGULATION_ID}`,
  REMOVE: `terms/${ApiParams.REGULATION_ID}`,
  PREVIEW: `terms/${ApiParams.REGULATION_ID}/preview`,
};

export const PAINTERS = {
  HOST_URL: 'http://api.ppg.loc/admin',
  LIST: 'painters',
  DETAILS: `painters/${ApiParams.CUSTOMER_ID}`,
  UPDATE: `painters/${ApiParams.CUSTOMER_ID}`,
  APPLICATIONS: {
    LIST: 'painters/applications',
    REMOVE: `painters/applications/${ApiParams.APPLICATION_ID}`,
    APPROVE: `painters/applications/${ApiParams.APPLICATION_ID}/approve`,
    REMIND: `painters/applications/${ApiParams.APPLICATION_ID}/remind`,
    REJECT: `painters/applications/${ApiParams.APPLICATION_ID}/reject`,
  },
};

export const PROMOTION_PUBLIC = {
  HOST_URL: 'http://api.ppg.loc/influencer',
  ENROLL: 'offers/subscribe',
};

export const PAINTERS_PUBLIC = {
  HOST_URL: 'http://api.ppg.loc/influencer',
  REGISTER: 'painters/apply',
  CONFIRM: `painters/confirm/${ApiParams.APPLICATION_ID}`,
};

export const COMPANIES_PUBLIC = {
  HOST_URL: 'http://api.ppg.loc/influencer',
  DETAILS: `companies/search/${ApiParams.NIP}`,
};
