import { Injectable } from '@angular/core';
import { ApiUrlBuilder } from '@api/core/builders/api-url.builder';
import { ApiParams } from '@environments/enums/api-params';

@Injectable({
  providedIn: 'root',
})
export class ApiUrlFactory {
  constructor(private readonly builder: ApiUrlBuilder) {}

  simple(host: string, path: string): string {
    return this.builder.host(host).path(path).build();
  }

  withParams(
    host: string,
    path: string,
    params: { [key in ApiParams]?: string },
  ): string {
    return this.builder.host(host).path(path).params(params).build();
  }

  withQueryParams(host: string, path: string, queryParams: Object): string {
    return this.builder.host(host).path(path).queryParams(queryParams).build();
  }

  complex(factory: (builder: ApiUrlBuilder) => string): string {
    return factory(this.builder);
  }
}
