import { Injectable } from '@angular/core';
import { Uuid } from '@core/uuid/uuid';
import { map, Observable } from 'rxjs';
import { RegulationsApiService } from '@api/regulation/regulations-api.service';
import { RegulationFactory } from '@features/regulations/factories/regulation.factory';
import { Regulation } from '@features/regulations/models/regulation';

@Injectable({
  providedIn: 'root',
})
export class FetchRegulationService {
  constructor(
    private readonly api: RegulationsApiService,
    private readonly offerFactory: RegulationFactory,
  ) {}

  byId(id: Uuid): Observable<Regulation> {
    return this.api
      .details(id)
      .pipe(map((response) => this.offerFactory.fromResponse(response)));
  }
}
