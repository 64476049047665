import { Directive, Input, OnInit } from '@angular/core';
import { Dialog } from 'primeng/dialog';

@Directive({
  selector: '[appSimpleDialog]',
  standalone: true,
})
export class SimpleDialogDirective implements OnInit {
  @Input() width!: string;

  constructor(private readonly dialog: Dialog) {}

  ngOnInit(): void {
    this.defaultConfiguration();
  }

  protected defaultConfiguration(): void {
    this.dialog.appendTo = 'body';
    this.dialog.draggable = false;
    this.dialog.transitionOptions = '0ms cubic-bezier(0, 0, 0.2, 1)';
    this.dialog.modal = true;
    this.dialog.blockScroll = false;
    this.dialog.closeOnEscape = false;

    if (this.width) {
      this.dialog.style = { width: this.width };
    }
  }
}
