import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { ValidationErrorDetailsResponse } from '@api/core/responses/validation-error-details.response';

@jsonObject
export class ValidationErrorResponse {
  @jsonMember readonly type!: string;
  @jsonMember readonly title!: string;
  @jsonMember({ name: 'detail' }) readonly message!: string;
  @jsonArrayMember(ValidationErrorDetailsResponse, { name: 'violations' })
  readonly details!: ValidationErrorDetailsResponse[];
}
